<template>
    <div class="section footer">
       This page have been created with 
        <img class="footer__heart" src='../assets/heart.svg' alt="love"/> 
    </div>
</template>

<script>
export default {};
</script>
<style>
.footer {
  background-color: #652d80;
  height: 10vh;
}
.footer__heart {
  width: 100%;
  max-width: 20px;
  margin: 0 0.5em;
}
</style>
