
import { Options, Vue } from "vue-class-component";
import Header from "./components/Header.vue";
import Content from "./components/Content.vue";
import Footer from "./components/Footer.vue";

@Options({
  components: {
    pdfatorHeader: Header,
    pdfatorFooter: Footer,
    pdfatorContent: Content,
  },
})
export default class App extends Vue {}
